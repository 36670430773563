import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import {
  isAuthenticated,
  authenticate,
  getTokenUserSegmentation,
  logUserV2,
} from "../api/requests";
import "../styles/login.css";
import "../styles/homescreen.css";
import "../styles/setplan.css";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Result } from "antd";

const antIcon = (
  <LoadingOutlined
    style={{ fontSize: 45, color: "#FF1E1E", marginBottom: 20 }}
    spin
  />
);

const IndoLogin = () => {
  const [error, setError] = useState(false);
  const [redirect, setRedirect] = useState();
  //   const [params] = useSearchParams();
  const msisdn = "85282293570";

  let payload = {
    msisdn: "62" + msisdn,
  };

  let loggingBody = {
    page: "indonesia_redirect_page",
    query: window.location.href,
  };

  useEffect(() => {
    const handleIndoLogin = () => {
      localStorage.clear();
      if (isAuthenticated() === false) {
        logUserV2(loggingBody).then((response) => {
          if (response.status === "200") {
            getTokenUserSegmentation(payload).then((response) => {
              if (response.status === 200) {
                localStorage.clear();
                authenticate(response.data.token, "62" + msisdn, "IND", () => {
                  setRedirect(true);
                });
              } else {
                setError(true);
              }
            });
          }
        });
      }
    };
    handleIndoLogin();
  }, []);

  if (redirect) {
    return <Navigate to={"/home"} />;
  }

  if (error) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Link to="/">Back Home</Link>}
      />
    );
  }

  return (
    <div class="loader">
      <Spin className="spinner" indicator={antIcon} tip="Redirecting..." />
    </div>
  );
};

export default IndoLogin;
